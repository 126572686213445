import React from "react";
import './FaydeSection.css'
import { FaBook, FaCalculator, FaGift, FaMicrophone, FaUsers } from "react-icons/fa";
import { SiOnlyoffice, SiWorkplace } from "react-icons/si";
const FaydeSection = () => {
  return (
    <div className="faydeContainer">
      <div className="sec1Heading">
        <span></span>
        <h2 className="">Kaksh Kalakar ke Benefits </h2>
        <span></span>
      </div>

      <div className="faydeBox">
        <div className="faydeRow">
          <div className="faydeItem">
            <div>
              <FaUsers/>
            </div>
            <p>Exclusive Community</p>
          </div>
          <div className="faydeItem">
            <div>
            <FaMicrophone/>
              
            </div>
            <p>Open Mics Invites</p>
          </div>
          <div className="faydeItem">
            <div>
              <FaBook/>
            </div>
            <p>Book Publishing  </p>
          </div>
          <div className="faydeItem">
            <div>
              <SiOnlyoffice/>
            </div>
            <p>Workshops</p>
          </div>
          <div className="faydeItem">
            <div>
              <FaGift/>
            </div>
            <p>Free Stuff</p>
          </div>
        </div>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSdHSR6BNZhQhtFZPag9v9VmOg9r3Sb50bTRZyc4ixj_CUfdrQ/viewform?usp=sf_link" target="_blank" className="join-Btn j-b-center">Start Here</a>

      </div>
    </div>
  );
};

export default FaydeSection;
