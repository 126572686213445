import React, { useState } from "react";
// import { Rating } from "@material-ui/lab";
import axios from "axios";
import "./Section2.css";
import { Sheet_Connection_Url } from "../../productionvariable";
import Recording from './Recording.js'

const Section2 = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [interested, setInterested] = useState("");
  const [place, setPlace] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const [isTrue, setIsTrue] = useState(false);

  // const alert = useAlert();

 const formSubmitHandler = ()=>{}
  // console.log("ok")

  return (
    <div className="Sec2MainContainer">
      <div className="Section2Container">
        <div className="box2">

          <div className="becomeKaksh">
            <h1>Aap bhi ban sakte hain कक्ष कलाकार</h1>
            <a className="join-Btn" target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSdHSR6BNZhQhtFZPag9v9VmOg9r3Sb50bTRZyc4ixj_CUfdrQ/viewform?usp=sf_link">Start Here</a>
          </div>

          {/* <h2>
          SEND US YOUR KALAKARI
          </h2> */}
          {/* <div className="sec2Heading">
            <span></span>
            <h2> Become a Kaksh Kalakar</h2>
            <span></span>
          </div> */}

          {/*<div className="box2Form ">
           <form action="" className="" onSubmit={formSubmitHandler}>
            {/* <h2>Feel Free to say us</h2> 
            {/* <p className="doblable">What are you do? </p> 
            <input
              type="text"
              className="inpStyle"
              required
              placeholder="What are you do ?"
              value={''}
            //   onChange={(e) => setDob(e.target.value)}
            />
            <input
              className="inpStyle"
              type="name"
              required
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
           
            <input
              type="phone"
              className="inpStyle"
              required
              placeholder="Phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <input
              type="email"
              className="inpStyle"
              required
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="text"
              className="inpStyle"
              required
              placeholder="Place"
              value={place}
              onChange={(e) => setPlace(e.target.value)}
            />
              <Recording/>
            <p id="loading"></p>
            <input type="submit" className="FormSectionBtn" disabled={isTrue} />
          </form> 

          
            
          </div>
          */}
        </div>
        <div className="box1">
          <img src={require('../../images/collage.png')} alt="lkj" />
        </div>
      </div>
      {/* <div className="Section2Container">
      <div className="box1">
          <img src="https://clipart-library.com/image_gallery2/Vector-PNG-Clipart.png" alt="" />

        </div>
        <div className="box2">
          
          <div className="sec2Heading">
            <span></span>
            <h2>  Aap bhi ban sakte hain कक्ष कलाकार
</h2>
            <span></span>
          </div>
          <p className="ptag">
          People often think that a PNG is a vector file, where in fact a PNG a raster image format. A raster image is made up of tiny squares or pixels. If you zoom in close, you will be able to see the small pixels that make up the image. Other common raster image files are JPEG, GIF and Bitmap formats.
          </p>
        </div>
       
      </div> */}
    </div>
  );
};

export default Section2;
