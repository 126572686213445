import React from 'react'
import './Section1.css'
import { FaBook, FaPodcast } from 'react-icons/fa'
import { BiCalendarEvent } from 'react-icons/bi'
import { SiLaunchpad } from 'react-icons/si'

const Section1 = () => {
  return (
    <div className="Section1Container">
        <div className="sec1Heading">
            <span></span>
            <h2>कलाकारों के लिए</h2>
            <span></span>

        </div>

        <div className="sec1Box">
            <div className="sec1Item">
                <FaPodcast/>
                <p>Podcast</p>
            </div>
            <div className="sec1Item">
                <FaBook/>
                <p>Publishing</p>
            </div>
            <div className="sec1Item">
                <BiCalendarEvent/>
                <p>Events</p>
            </div>
        </div>
    </div>
    )
}

export default Section1