import React, { useEffect, useState } from "react";
// import { Rating } from "@material-ui/lab";
import axios from "axios";
import "./ContactSection.css";
import { Sheet_Connection_Url } from "../../productionvariable";

import Modal from 'react-modal';
import { AiOutlineClose } from "react-icons/ai";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};


const ContactSection = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [interested, setInterested] = useState("");
  const [isTrue, setIsTrue] = useState(false);

  // const alert = useAlert();
  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    // console.log(name, email, rating, dob)
    const data = {
      name,
      email,
      phone,
      interested,
    };

    document.getElementById("loading").innerHTML = "Proccessing...";
    setIsTrue(true);

    await axios
      .post(Sheet_Connection_Url, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
        },
      })
      .then((res) => {
        // console.log(res);
        alert("Email Send SuccussFully. Thank-you for Join us");
        // setIsOpen();
        setCookie('popoff', 'off', 7);

        window.location.reload();
      })
      .catch((error) => {
        document.getElementById("loading").innerHTML = "";
        setIsTrue(false);

        const checkDupicate = error.response.data.message.search("duplicate");
        // console.log(checkDupicate)
        if (checkDupicate !== -1) {
          alert("This Email is Already Exist. Please Enter new Email");
        }

        alert(`Technical Error. ${error.response.data.message}`);
      });
  };
  // console.log("ok")
  function addEvent(obj, evt, fn) {
    if (obj.addEventListener) {
        obj.addEventListener(evt, fn, false);
    }
    else if (obj.attachEvent) {
        obj.attachEvent("on" + evt, fn);
    }
}

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  let popoff = 'null'


  useEffect(() => {
    // popoff = document.cookie ? document.cookie : 'null'
     function getCookie(name) {
      function escape(s) { return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1'); }
      var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
      return match ? match[1] : null;
  }
  popoff = getCookie('popoff')
    // console.log(popoff)

    if(popoff != 'off'){
      setIsOpen(true)
    }

    const minutes = 0.2, the_interval = minutes * 60 * 1000;
 

  setTimeout(function() {
    // openModal()
    if(popoff != 'off'){
      setIsOpen(true)
    }
  }, the_interval);

  addEvent(document, "mouseout", function(e) {
    e = e ? e : window.event;
    var from = e.relatedTarget || e.toElement;
    if (!from || from.nodeName == "HTML") {
        // stop your drag event here
        // for now we can just use an alert
        // alert("left window");
        if(popoff != 'off'){
          setIsOpen(true)
        }
    }
});

 
}, [])

  
  

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }


  return (
    <>
      <div className="contactSectionContainer">
        <div className="modalContainer">
          {/* <button onClick={openModal}>Open Modal</button> */}

          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <div className="closebox">
            <h2>Become a Kaksh Kalakar and get featured on our podcast.</h2>
            <button onClick={closeModal} id="closeBtn"><AiOutlineClose/></button>
            </div>
            <div className="memeTag">
                <video src={require('../../images/meme.mp4')} autoPlay loop width={'400'}></video>

            </div>

            <div className="box1 modalBox ">
              <form action="" className="" onSubmit={formSubmitHandler}>
                {/* <h2>Feel Free to say us</h2> */}
                {/* <p className="doblable">What are you do? </p> */}
                {/* <select
                  id="product"
                  value={interested}
                  onChange={(e) => setInterested(e.target.value)}
                  className="inpStyle"
                  required
                  name="Product"
                >
                  <option value="">Interested in</option>
                  <option value="podcast">Podcast</option>
                  <option value="book Publishing">Book Publishing</option>
                  <option value="sponsorship/Advertising"> Sponsorship/Advertising</option>
                  <option value="event"> Event</option>
                </select> */}
                {/* <input
                  className="inpStyle"
                  type="name"
                  required
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                /> */}

                {/* <input
                  type="phone"
                  className="inpStyle"
                  required
                  placeholder="Phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <input
                  type="email"
                  className="inpStyle"
                  required
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />*/}
                
                {/* <p id="loading"></p>
                <input type="submit" className="FormSectionBtn" disabled={isTrue} /> */}
              </form>
              <p className="recP">Share your podcast Recording </p> 
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSdHSR6BNZhQhtFZPag9v9VmOg9r3Sb50bTRZyc4ixj_CUfdrQ/viewform?usp=sf_link" target="_blank" className="join-Btn">Start Here</a>

            </div>
          </Modal>
        </div>

        <div className="box2">
          <h2>
            CONTACT US
          </h2>

          <div className="contactLinkBox">
            {/* <p> Some of the best client testimonial examples include: 1. Quote Testimonials. When you think of testimonials, you’re probably picturing the traditional quote type of testimonial.</p> */}
            <a href="mail:kaksh@pepperscript.com"> <b>Email : </b>  kaksh@pepperscript.com </a>
            <a href="tel:+91 98731 93332"> <b>Phone : </b> +91 98731 93332 </a>
            <a href=""> <b>Location</b> Inderlok, Delhi</a>

          </div>

          <div>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSdHSR6BNZhQhtFZPag9v9VmOg9r3Sb50bTRZyc4ixj_CUfdrQ/viewform?usp=sf_link" target="_black" className="join-Btn">Start here</a>
            {/* <p>Its Free</p> */}
          </div>
        </div>
        <div className="box1">
          <form action="" className="" onSubmit={formSubmitHandler}>
            {/* <h2>Feel Free to say us</h2> */}
            {/* <p className="doblable">What are you do? </p> */}
            <select
              id="product"
              value={interested}
              onChange={(e) => setInterested(e.target.value)}
              className="inpStyle"
              required
              name="Product"
            >
              <option value="">Interested in</option>
              <option value="podcast">Podcast</option>
              <option value="book Publishing">Book Publishing</option>
              <option value="sponsorship/Advertising"> Sponsorship/Advertising</option>
              <option value="event"> Event</option>
            </select>
            <input
              className="inpStyle"
              type="name"
              required
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <input
              type="phone"
              className="inpStyle"
              required
              placeholder="Phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <input
              type="email"
              className="inpStyle"
              required
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <p id="loading"></p>
            <input type="submit" className="FormSectionBtn" disabled={isTrue} />
          </form>
        </div>
      </div>
    </>
  );
};

export default ContactSection;
