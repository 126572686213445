import React from "react";
import CarouselSlide from "../Carousel/Carousel.js";
import FormSection from "../FormSection/FormSection.js";
import Section1 from "../Section1/Section1.js";
import Section2 from "../Section2/Section2.js";
import ScrollSection from "../ScrollSection/ScrollSection.js";
import FaydeSection from "../FaydeSection/FaydeSection.js";
import ContactSection from "../ContactSection/ContactSection.js";
import Accordion from "../Accordion/Accordion.js";
// import productImage from '../../images/Orange_1_540x.webp'
// import { Link } from 'react-router-dom'
import "./home.css";
import "./home2.css";


const Home = () => {
  return (
    <div>
      <FormSection />
      <Section1/>

      <div className="highlightBanner">
        <div className="bannerbox">
            <img src={require('../../images/event\ banner.png')} alt="" />
        </div>
      </div>

      {/* // Voice */}
      <Section2/> 
      <ScrollSection/>
      <FaydeSection/>
      {/* <Accordion/> */}
      {/* <ContactSection/> */}
   
    </div>
  );
};

export default Home;
