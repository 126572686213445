export const YOUR_PUBLIC_KEY = "fKpPsuTMAw0JwZGsQ"
export const YOUR_TEMPLATE_ID = "template_5kohtgf"
export const YOUR_SERVICE_ID = "service_7nisf8i"
// export const Sheet_Connection_Url = "https://sheet.best/api/sheets/c7dd82b4-6623-4399-9b75-3004a18b8b43"
// export const Sheet_Connection_Url = "https://script.google.com/macros/s/AKfycbzAaccIZEPRvqGKyOpe7XSNyqMYRt7BkziUY4QIsPwUjueIFiMXar2eEXx6oRXAvPAx/exec?action=addUser"

// export const Sheet_Connection_Url = "https://fragrens.onrender.com/api/v1/boutique/form"
// export const Sheet_Connection_Url = "http://localhost:5001/api/v1/create"
// export const url = "http://localhost:5001"

export const Sheet_Connection_Url = "http://kaksh.pepperscript.com/api/v1/create"
export const url = "kaksh.pepperscript.com"
