import React, { useState } from "react";
// import { Rating } from "@material-ui/lab";
import axios from "axios";
import "./FormSection.css";
import { Sheet_Connection_Url } from "../../productionvariable";

const FormSection = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [interested, setInterested] = useState("");
  const [place, setPlace] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");

  const [isTrue, setIsTrue] = useState(false);

  // const alert = useAlert();

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    // console.log(name, email, rating, dob)
    const data = {
      name,
      email,
      phone,
      message,
      place,
      interested,
      company,
    };

    document.getElementById("loading").innerHTML = "Proccessing...";
    setIsTrue(true);

    await axios
      .post(Sheet_Connection_Url, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
        },
      })
      .then((res) => {
        // console.log(res);
        alert("Email Send SuccussFully. Thank-you for Join us");
        window.location.reload();
      })
      .catch((error) => {
        document.getElementById("loading").innerHTML = "";
        setIsTrue(false);

        const checkDupicate = error.response.data.message.search("duplicate");
        // console.log(checkDupicate)
        if (checkDupicate !== -1) {
          alert("This Email is Already Exist. Please Enter new Email");
        }

        alert(`Technical Error. ${error.response.data.message}`);
        // console.log(error.response.data.message)
        // console.log(response.message)
        // window.location.reload()
      });
  };
  // console.log("ok")

  return (
    <>
      <div className="FormSectionContainer">
        <div className="box2">
          <h2>Ab har आवाज़, Banegi ख़ास।</h2>
          <div>
            <p>JOIN OUR COMMUNITY </p>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSdHSR6BNZhQhtFZPag9v9VmOg9r3Sb50bTRZyc4ixj_CUfdrQ/viewform?usp=sf_link" target="_blank" className="join-Btn">Start Here</a>
            <p>Its Free</p>
          </div>
          {/* <form action="" className="" onSubmit={formSubmitHandler}>
            <select
              id="product"
              value={interested}
              onChange={(e) => setInterested(e.target.value)}
              className="inpStyle"
              required
              name="Product"
            >
              <option value="">Interested in</option>
              <option value="Bathing Amenities">Bathing Amenities</option>
              <option value="Spa Products">Spa Products</option>
              <option value="Essential Oils"> Essential Oils</option>
              <option value="Aroma Diffusers"> Aroma Diffusers</option>
              <option value="Incense Sticks"> Incense Sticks</option>
            </select>

            <input
              className="inpStyle"
              type="name"
              required
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {/* <p className="doblable">Select date of Birth </p>
            <input
              type="date"
              className="dob"
              required
              placeholder=""
              value={dob}
              onChange={(e) => setDob(e.target.value)}
            /> 
               <input
              type="phone"
              className="inpStyle"
              required
              placeholder="Phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <input
              type="email"
              className="inpStyle"
              required
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
   {/* <input
              type="text"
              className="inpStyle"
              required
              placeholder="Place"
              value={place}
              onChange={(e) => setPlace(e.target.value)}
            /> 
               <input
              type="text"
              className="inpStyle"
              required
              placeholder="Company"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
            />
            {/* <textarea name="" width="100%" placeholder="Message" value={message} onChange={e=>setMessage(e.target.value)} className="inpStyle" id="" cols="30" rows="5"></textarea> */}
          {/* <select
              id="product"
              value={product}
              onChange={(e) => setProduct(e.target.value)}
              className="inpStyle"
              required
              name="Product"
            >
              <option value="">Product you bought</option>
              <option value="carrier oils">Carrier oils</option>
              <option value="essential oil">Essential oils</option>
              <option value="fragrance oil"> Fragrance oils</option>
              <option value=" fragrance oil"> Purpose Oils</option> 
              <option value=" fragrance oil"> Nothing yet</option>
            </select> 

            {/* <div className="ratingBox">
              <p>Rating</p>
               <Rating  onChange={(e)=>setRating(e.target.value)} value={rating} size="large" /> 
            </div>
            

            <p id="loading"></p>
            <input type="submit" className="FormSectionBtn" disabled={isTrue} />
          </form> */}
        </div>
        <div className="box1">
          <img src={require("../../images/kaksh1.png")} alt="" />
        </div>
      </div>
    </>
  );
};

export default FormSection;
