import React from "react";
import "./ScrollSection.css";
import Rating from "react-rating-stars-component";

const ScrollSection = () => {

  return (
    <div className="scrollContainer">
      <div className="sec1Heading">
        <span></span>
        <h2 className="sec1Headingh2">Testimonials</h2>
        <span></span>
      </div>

      <div className="productScroll">
        {/* <Carousel> */}
        {/* <div className="SlideItem">
              <img src={require('../../images/dropper_bottle_mockup_black_cap_720x.webp')} width='100%' height='90%' alt="" />
              <h3><a rel="noreferrer"  target='_blank' href="https://thefragrens.com/collections/essential-oils/products/winter-shield">Winter-shield</a> </h3>
            </div> */}
        <div className="SlideItem">
          <div className="nameBox">
            <h3>Sneha Amrodiya</h3>
            {/* <p>Director , Radio Sai</p> */}
          </div>
          <div className="ratingBox">
            <Rating
              //  count={5}
              size={36}
              value={5}
              activeColor="#f0a52c"
            />
            <p>
              बिना अर्ग के भला कौन पुष्प बिखेर खुशबू पाता बिना सीढ़ी के भला फिर
              कौन आसमान छू पाता <br /> <br />
              Thankyou "Kaksh Kalakar" for becoming the ladder for the path of
              new artist by giving a recognition to our voice. The work you are
              doing in this field is truly appreciated and I am very very
              thankful to you and wishing you a immensive success.
            </p>
          </div>
        </div>

        <div className="SlideItem">
          <div className="nameBox">
            <h3>Dipika Chawla</h3>
            {/* <p>Director , Radio Sai</p> */}
          </div>
          <div className="ratingBox">
            <Rating
              //  count={5}
              size={36}
              value={5}
              activeColor="#f0a52c"
            />
            <p>
            लिखने की कोई उमर नहीं
लिखने की हाँ वजह कई
उस वजह को कलम से सजाते रहिये अपनी सोच से लफ्ज़ों कि माला बनाते रहिये 'कक्ष्कलाकार' एक ऐसा खूबसूरत माध्यम है,
ये एक ऐसे सूत्रधार हैं जो आपकी आवाज़ कई दिलों तक पहुंचाते हैं
इनसे जुड़िये और अपने लफ्ज़ों को कक्ष्कलाकार कि माला में पिरोकर एक नया रूप दिजीये
            </p>
          </div>
        </div>

        <div className="SlideItem">
          <div className="nameBox">
            <h3>Prof Sudhir Bhaskar</h3>
            <p>Director , Radio Sai</p>
          </div>
          <div className="ratingBox">
            <Rating
              //  count={5}
              size={36}
              value={5}
              activeColor="#f0a52c"
            />
            <p>
              Bishu has gently unwrapped the different roles Swami plays in our
              lives beautifully. A worthy read!
            </p>
          </div>
        </div>

        <div className="Scrollborder">
          <div> </div>
        </div>

        {/* </Carousel> */}
      </div>
    </div>
  );
};

export default ScrollSection;
